import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { CollapsibleContainer } from '../CollapsibleContainer'
import { DebounceInput } from 'react-debounce-input'
import FilterSlider from '@Components/shared/SliderComponents/FilterSlider'
import { Row } from 'react-bootstrap'

/**Collapsible filter for the minimum and maximum model year of displayed cars.
 * @param {Function} [setPageNumber] callback to set the current page number on filter change.
 * @param {Number} [timeout=500] the time in miliseconds to wait before firing onChange event from user interaction.
 */
export const YearFilter = ({ setPageNumber, timeout = 500 }) => {
  const { state, dispatch } = useContext(FilterContext)

  const onChange = payload => {
    dispatch({ type: actionTypes.YEARS, payload })

    setPageNumber && setPageNumber(1)
  }

  const handleSliderChange = ([currentLow, currentHigh]) => onChange({ currentLow, currentHigh })

  const onInputChange = (value, propertyName) => {
    const nextValue = Number(value.replace(/\D/g, ''))

    if (nextValue === state.years[propertyName] ||
      nextValue < state.years.lowerBound ||
      nextValue > state.years.upperBound) return

    onChange({ [propertyName]: nextValue })
  }

  const disabled = state.years.lowerBound === state.years.upperBound

  return <CollapsibleContainer title='YEAR'>
    <Row className='justify-content-around'>
      <div className='car-filter-input-container' >
        <DebounceInput
          disabled={disabled}
          className='car-filter-input'
          onChange={e => onInputChange(e.target.value, 'currentLow')}
          value={state.years.currentLow}
          debounceTimeout={timeout}
        />
        -
        <DebounceInput
          disabled={disabled}
          className='car-filter-input'
          onChange={e => onInputChange(e.target.value, 'currentHigh')}
          value={state.years.currentHigh}
          debounceTimeout={timeout}
        />
      </div>
    </Row>
    <FilterSlider
      disabled={disabled}
      priceRange={[state.years.lowerBound, state.years.upperBound]}
      currentLow={state.years.currentLow}
      currentHigh={state.years.currentHigh}
      handleSliderChange={handleSliderChange}
    />
  </CollapsibleContainer>
}
