import './styles.scss'

import { Col, Container, Row } from 'react-bootstrap'

import Ad from './Ad'
import { AdContent } from './AdContent'
import React from 'react'

const AdContainer = () =>
  <Col md='12' className='desktop-ads'>
    <Container className='p-0 mb-4'>
      <Row className='row-cols-3'>
        {Object.entries(AdContent).map(([title, { to, text, className }], index) =>
          <Col key={index} className={className}>
            <Ad title={title} to={to} text={text} />
          </Col>)}
      </Row>
    </Container>
  </Col>

export default AdContainer
