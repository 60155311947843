import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { CollapsibleContainer } from '../CollapsibleContainer'
import FilterSlider from '@Components/shared/SliderComponents/FilterSlider'
import { Row } from 'react-bootstrap'
import { formatWeeksTimeString } from '@Helpers'

export const AvailabilityFilter = ({ setPageNumber }) => {
  const { state, dispatch } = useContext(FilterContext)

  const handleSliderChange = ([currentLow, currentHigh]) => {
    setPageNumber(1)
    dispatch({ type: actionTypes.AVAILABILITY, payload: { currentLow, currentHigh } })
  }

  const disabled = state.availability.lowerBound === state.availability.upperBound

  return <CollapsibleContainer title='AVAILABILITY'>
    <Row className='justify-content-around'>
      <div className='car-filter-input-container' >
        <label className='car-filter-label'>{formatWeeksTimeString(state.availability.currentLow)}</label>
          -
          <label className='car-filter-label'>{formatWeeksTimeString(state.availability.currentHigh)}</label>
      </div>
    </Row>
    <FilterSlider
      disabled={disabled}
      priceRange={[state.availability.lowerBound, state.availability.upperBound]}
      currentLow={state.availability.currentLow}
      currentHigh={state.availability.currentHigh}
      handleSliderChange={handleSliderChange}
      format='availability'
    />
  </CollapsibleContainer>
}
