import './styles.scss'

import { FilterContext, actionTypes } from '@Contexts/Filter'
import { Handle, Tick, Track } from './SliderComponents' // example render components - source below
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider'
import React, { useContext } from 'react'

import formatTicks from './formatTicks'

const sliderStyle = {
  position: 'relative',
  width: '100%',
  height: 30
}

const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 4,
  marginTop: 0,
  borderRadius: 5,
  backgroundColor: '#80d2ff'
}

const FilterSlider = ({ priceRange, handleSliderChange, currentLow, currentHigh, step, format, disabled }) => {
  const { dispatch } = useContext(FilterContext)

  const resetPage = () => dispatch({ type: actionTypes.SET_PAGE, payload: 0 })

  const [lowPrice, highPrice] = priceRange

  //Breaking out of slider render due to ungraceful handling of matching slider extremes
  //by react-compound-slider library
  if (disabled || lowPrice === highPrice) return null

  return <div className='slider-container'>
    <Slider
      step={step || 1}
      rootStyle={sliderStyle}
      domain={[lowPrice, highPrice]}
      values={[currentLow, currentHigh]}
      onChange={(e) => handleSliderChange(e)}
      onSlideEnd={resetPage}
    >
      <div style={railStyle} />
      <Rail>
        {({ getRailProps }) => <div style={railStyle} {...getRailProps()} /> /*Adding the Rail Props sets up events on the rail*/}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) =>
          <div className='slider-handles'>
            {handles.map(handle => <Handle
              key={handle.id}
              handle={handle}
              getHandleProps={getHandleProps}
              domain={[lowPrice, highPrice]}
            />)}
          </div>}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) =>
          <div className='slider-tracks'>
            {tracks.map(({ id, source, target }) => <Track
              key={id}
              source={source}
              target={target}
              getTrackProps={getTrackProps}
            />)}
          </div>}
      </Tracks>
      <Ticks values={priceRange}>
        {({ ticks }) => <div className='slider-ticks'>
          {ticks.map(tick => <Tick key={tick.id} tick={tick} count={ticks.length} format={(e) => formatTicks(e, format)} />)}
        </div>}
      </Ticks>
    </Slider>
  </div>
}

export default FilterSlider
