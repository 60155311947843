import axios, { CancelToken, CancelTokenSource } from 'axios' // eslint-disable-line no-unused-vars
import { fetchStockImages, getFilteredList, queryImageCache } from '@Services/API'
import { handleUserDeauth, logError } from '@Services'

import { updateTimestamp } from '@Services/Credentials'

/**Converts a given make object (expected from FilterContext) to a string in the format
 * expected from the server for the car makes filter.
 * @param selectedMakes The FilterContext.make object to be serialized.
 */
const serializeSelectedMakes = selectedMakes => {
    let makesToSearch = Object.entries(selectedMakes)
        .reduce((accumulator, [key, value]) => accumulator + (value ? key + ',' : ''), '')
        .toLowerCase()

    return makesToSearch.length ? makesToSearch : Object.keys(selectedMakes).join(',').toLowerCase()
}

/**Cancels a stale token if it exists
 * @param {CancelToken} cancelToken The currently held cancellationToken
 * @param {*} setLoading The loading state update function
 */
const cancelStaleRequest = (cancelToken, setLoading) => {
    if (cancelToken) {
        cancelToken.cancel('A newer request has been issued')
        setLoading(prevLoadingCount => prevLoadingCount - 1)
    }
}

/**Creates, registers and returns a new cancellation token source to be used with the next axios request.
 * @param {*} setCancelToken The cancellationToken state update function
 * @returns {CancelTokenSource} The newly registered cancellation token source.
 */
const registerCancellationToken = setCancelToken => {
    const cancelTokenSource = axios.CancelToken.source()

    setCancelToken(cancelTokenSource)

    return cancelTokenSource
}

/**Fetches and sets stock photos for cars that are missing images, 
 * given the car list response object from the server.
 * @param response The response object from the server after making a car list request
 */
const appendStockPhotos = async response => {
    if (!response?.vehicle?.length) return response

    const searchTerms = response.vehicle.reduce((accumulator, currentCar) => {
        if (!currentCar.glamourphotos?.length) {
            accumulator.add(`${currentCar.year} ${currentCar.make} ${currentCar.model}`)
        }

        return accumulator
    }, new Set())

    await fetchStockImages([...searchTerms])

    response.vehicle.forEach(car => {
        if (!car.glamourphotos?.length)
            car.stockPhoto = queryImageCache(`${car.year} ${car.make} ${car.model}`)
    })

    return response
}

/**Fetches a list of cars from the server and loads them into the page's state to be rendered.
 * @param {*} auth The context yielded by AuthContext
 * @param {*} filter The context yielded by FilterContext
 * @param {*} setLoading The callback to the loading indicator
 * @param {*} currentCancelToken The currently held fetch cancellationToken
 * @param {*} setCancelToken The callback to update the held fetch cancellationToken
 * @param {*} setCarList The callback to update the currently loaded car list
 */
export const updateCarListings = async (auth, filter, setLoading, currentCancelToken, setCancelToken, setCarList) => {
    try {
        setLoading(prevLoadingCount => prevLoadingCount + 1)

        cancelStaleRequest(currentCancelToken, setLoading)
        const cancelTokenSource = registerCancellationToken(setCancelToken)

        const { data } = await getFilteredList(
            { ...filter.state, make: serializeSelectedMakes(filter.state.make) },
            auth.state.token,
            cancelTokenSource.token
        )
        updateTimestamp()

        await appendStockPhotos(data)
        setCarList(data)
        setLoading(prevLoadingCount => prevLoadingCount - 1)
        setCancelToken(null) //Request has completed. Clear cancel token.
    } catch (error) {
        if (axios.isCancel(error)) return //Axios cancellations will bubble up here. We do not need to report these.
        if (handleUserDeauth(error, auth.dispatch)) return

        logError(error)
        setLoading(false)
    }
}
