import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { CollapsibleContainer } from '../CollapsibleContainer'
import { DebounceInput } from 'react-debounce-input'
import FilterSlider from '@Components/shared/SliderComponents/FilterSlider'
import { Row } from 'react-bootstrap'
import { numberWithCommas } from '@Helpers'

/**Collapsible filter for the minimum and maximum mileage of displayed cars.
 * @param {Function} [setPageNumber] callback to set the current page number on filter change.
 * @param {Number} [timeout=500] the time in miliseconds to wait before firing onChange event from user interaction.
 */
export const MileageFilter = ({ setPageNumber, timeout = 500 }) => {
  const { state, dispatch } = useContext(FilterContext)

  const onChange = payload => {
    dispatch({ type: actionTypes.MILEAGE, payload })

    setPageNumber && setPageNumber(1)
  }

  const handleSliderChange = ([currentLow, currentHigh]) => onChange({ currentLow, currentHigh })

  const onInputChange = (value, propertyName) => {
    const nextValue = Number(value.replace(/\D/g, ''))

    if (nextValue === state.price[propertyName] ||
      nextValue < state.price.lowerBound ||
      nextValue > state.price.upperBound) return

    onChange({ [propertyName]: nextValue })
  }

  return <CollapsibleContainer title='MILEAGE'>
    <Row className='justify-content-around'>
      <div className='car-filter-input-container' >
        <DebounceInput
          className='car-filter-input'
          onChange={e => onInputChange(e.target.value, 'currentLow')}
          value={numberWithCommas(state.mileage.currentLow)}
          debounceTimeout={timeout}
        />
            -
          <DebounceInput
          className='car-filter-input'
          onChange={e => onInputChange(e.target.value, 'currentHigh')}
          value={numberWithCommas(state.mileage.currentHigh)}
          debounceTimeout={timeout}
        />
      </div>
    </Row>
    <FilterSlider
      priceRange={[state.mileage.lowerBound, state.mileage.upperBound]}
      currentLow={state.mileage.currentLow}
      currentHigh={state.mileage.currentHigh}
      handleSliderChange={handleSliderChange}
      format={'miles'}
    />
  </CollapsibleContainer>
}
