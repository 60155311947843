import { numberWithCommas } from '@Helpers'

const formatTicks = (value, format) => {
    switch (format) {
        case 'dollar':
            return `$${numberWithCommas(parseInt(value))}`;
        case 'miles':
            return `${numberWithCommas(parseInt(value))} mi`;
        case 'availability':
            if (value === 0) {
                return 'Today'
            }
            else {
                return `${value === 5 ? 6 : value} Weeks${value === 5 ? '+' : ''}`
            }
        default:
            return value;
    }
}

export default formatTicks