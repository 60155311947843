import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { CollapsibleContainer } from '../CollapsibleContainer'
import { DebounceInput } from 'react-debounce-input'
import FilterSlider from '@Components/shared/SliderComponents/FilterSlider'
import { Row } from 'react-bootstrap'
import { formatCurrency } from '@Helpers'

/**Collapsible filter for the minimum and maximum price of displayed cars.
 * @param {Function} [setPageNumber] callback to set the current page number on filter change.
 * @param {Number} [timeout=500] the time in miliseconds to wait before firing onChange event from user interaction.
 */
export const PriceFilter = ({ setPageNumber, timeout = 500 }) => {
  const { state, dispatch } = useContext(FilterContext)

  const onChange = payload => {
    dispatch({ type: actionTypes.PRICE, payload })
    setPageNumber && setPageNumber(1)
  }

  const handleSliderChange = ([currentLow, currentHigh]) => onChange({ currentLow, currentHigh })

  const onInputChange = (value, propertyName) => {
    const nextValue = Number(value.replace(/\D/g, ''))

    if (nextValue === state.price[propertyName] ||
      nextValue < state.price.lowerBound ||
      nextValue > state.price.upperBound) return

    onChange({ [propertyName]: nextValue })
  }

  const disabled = state.price.lowerBound === state.price.upperBound

  return <CollapsibleContainer title='PRICE'>
    <Row className='justify-content-center'>
      <div className='car-filter-input-container' >
        <span className='input-dollar'>
          <DebounceInput
            disabled={disabled}
            className='car-filter-input'
            onChange={e => onInputChange(e.target.value, 'currentLow')}
            value={formatCurrency(state.price.currentLow)}
            debounceTimeout={timeout}
          />
        </span>
            -
            <span className='input-dollar'>
          <DebounceInput
            disabled={disabled}
            className='car-filter-input'
            onChange={e => onInputChange(e.target.value, 'currentHigh')}
            value={formatCurrency(state.price.currentHigh)}
            debounceTimeout={timeout}
          />
        </span>
      </div>
    </Row>
    <FilterSlider
      disabled={disabled}
      priceRange={[state.price.lowerBound, state.price.upperBound]}
      currentLow={state.price.currentLow}
      currentHigh={state.price.currentHigh}
      handleSliderChange={handleSliderChange}
      format='dollar'
    />
  </CollapsibleContainer>
}
