import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

export const ClearFilters = () => {
    const { state, dispatch } = useContext(FilterContext)
    const filterTotal = Object
        .values(state.active)
        .reduce((accumulator, isActive) => accumulator + Number(isActive))

    return <div
        role='button'
        className='filter-container-clear-filter-item'
        onClick={() => dispatch({ type: actionTypes.RESET_FILTERS })}
        onKeyPress={() => dispatch({ type: actionTypes.RESET_FILTERS })}
        tabIndex={0}
    >
        Clear All ({filterTotal})
    </div>
}