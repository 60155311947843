import './styles.scss'

import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { Col } from 'react-bootstrap'
import { CollapsibleContainer } from '../CollapsibleContainer'
import InputMask from 'react-input-mask'
import Select from 'react-select'

const options = [
  { value: 'any', label: 'Any' },
  { value: '50', label: '50 Miles' },
  { value: '100', label: '100 Miles' },
  { value: '500', label: '500 Miles' },
]

export const LocationFilter = () => {
  const { state, dispatch } = useContext(FilterContext)

  const onSelectChange = ({ value }) => dispatch({ type: actionTypes.RADIUS, payload: value })

  const onZipCodeChange = ({ target: { value } }) => {
    const trimmedValue = value.replace(/\D/g, '')

    dispatch({ type: actionTypes.ZIPCODE, payload: trimmedValue })
  }

  return <CollapsibleContainer title='LOCATION' >
    <div className='radius-zip-filter car-filter'>
      <Col>
        <div>Radius</div>
        <div className='car-filter-input-container'>
          <Select
            isSearchable={false}
            onChange={onSelectChange}
            options={options}
            value={options.find(option => option.value === state.radius)}
            className='car-filter-input'
          />
        </div>
      </Col>
      <Col>
        <div className='car-filter-input-container'>
          <div>Zip code</div>
          <InputMask
            className='car-filter-input'
            onChange={onZipCodeChange}
            value={state.zipcode}
            mask={state.zipcode.length === 5 ? '999999999' : '99999-9999'}
            maskChar={null}
          />
        </div>
      </Col>
    </div>
  </CollapsibleContainer>
}
