import './styles.scss'

import { Card, Col, Row } from 'react-bootstrap'

import { Link } from 'gatsby'
import { Photo } from './Photo'
import React from 'react'
import { numberWithCommas } from '@Helpers'

/** Represents a single car card on the Search page results list.
 * 
 * @param props The properties of a single car response object from the search endpoint
 */
export const CarCard = ({ stockid, stockPhoto, year, make, model, price, mileage, city, state, glamourphotos, manufacturerphotos }) =>
    <Card className='mb-4'>
        <Link data-testid='car-link' to={`/Details/${stockid}`}>
            <Photo glamourphotos={glamourphotos} stockPhoto={stockPhoto} manufacturerphotos={manufacturerphotos} />
            <Card.Body>
                <Row className='text-dark'>
                    <Col sm='9' md='9' className='p-0'>
                        {`${year} ${make} ${model}`}
                    </Col>
                    <Col sm='3' md='3' className='p-0 text-right'>
                        {`$${numberWithCommas(price).toLocaleString()}`}
                    </Col>
                </Row>
                <Row className='text-small text-muted mt-3'>
                    <Col>
                        {`${numberWithCommas(mileage).toLocaleString()} Miles`}
                    </Col>
                    <Col className='text-uppercase text-right'>
                        {`${city}, ${state}`}
                    </Col>
                </Row>
            </Card.Body>
        </Link>
    </Card>
