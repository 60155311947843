import React, { useState } from 'react'

import { CollapsibleContainer } from '../CollapsibleContainer'
import { RadioFilter } from '@Components/shared/RadioComponents/RadioFilter'

/**Collapsible filter for SiriusXM support of displayed cars.
 * This is currently a read only stub as all cars are expected to support SiriusXM.
 * @param {Boolean} defaultActive whether this filter should start enabled or disabled.
 * @param {Boolean} disabled whether this filter can be toggled or is locked in it's current state.
 */
export const SiriusXM = ({ disabled, defaultActive }) => {
  const [active, setActive] = useState(defaultActive)

  const onClick = () => {
    if (disabled) return

    setActive(!active)
    //Implement as a FilterContext actionType if ever required.
    //dispatch({ type: actionTypes.TODO, payload: TODO })
  }

  return <CollapsibleContainer title='AUDIO'>
    <RadioFilter
      options={[{ text: 'SiriusXM', value: 'siriusXM', active, disabled }]}
      onClick={onClick}
    />
  </CollapsibleContainer>
}
