import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext, useState } from 'react'

import { Row } from 'react-bootstrap'
import Select from 'react-select'
import { SlideDown } from 'react-slidedown'
import TextInput from '@Components/shared/Inputs/TextInput'

const options = [
    { value: 'New%20Arrivals', label: 'New Arrivals' },
    { value: 'Distance', label: 'Distance' },
    { value: 'Lowest%20Price', label: 'Lowest Price' },
    { value: 'Highest%20Price', label: 'Highest Price' },
    { value: 'Lowest%20Mileage', label: 'Lowest Mileage' },
    { value: 'Highest%20Mileage', label: 'Highest Mileage' },
    { value: 'Newest%20Year', label: 'Newest Year' },
    { value: 'Oldest%20Year', label: 'Oldest Year' },
]

//TODO: Move these to scss
//https://react-select.com/styles#using-classnames
const customStyles = {
    container: () => ({
        width: 160,
        position: 'relative',
    }),
    control: () => ({
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    }),
    placeholder: () => ({
        color: '#1175a9',
        paddingBottom: 10,
    }),
    singleValue: () => ({
        color: '#1175a9',
    }),
    indicatorSeparator: () => ({
        opacity: 1,
    }),
    indicatorsContainer: () => ({
        paddingBottom: 7,
    }),
    dropdownIndicator: () => ({
        color: '#1175a9',
        paddingBottom: 5,
    }),
    input: () => ({
        color: '#1175a9',
    }),
    valueContainer: () => ({
        display: 'flex',
        paddingBottom: 2,
        paddingLeft: 5,
    })
}

export const Sort = () => {
    const { dispatch } = useContext(FilterContext)
    const [displayZipCode, setDisplayZipCode] = useState('')
    const [isDistanceSelected, setIsDistanceSelected] = useState(false)

    let handleSelect = ({ value }) => {
        const isDistanceSelected = value === 'Distance'

        setIsDistanceSelected(isDistanceSelected)

        !isDistanceSelected && dispatch({ type: actionTypes.SORT_BY, payload: value })
    }

    const onZipCodeChange = ({ target: { value } }) => {
        setDisplayZipCode(value)

        if (value.length === 0 || value.length === 5 || value.length === 10) {
            dispatch({ type: actionTypes.ZIPCODE, payload: value })
            dispatch({ type: actionTypes.SORT_BY, payload: 'Distance' })
        }
    }

    return <Row className='search-container-sort'>
        <Select
            isSearchable={false}
            placeholder='Sort...'
            options={options}
            onChange={handleSelect}
            styles={customStyles}
        />
        {isDistanceSelected && <div className='zip-search-input'>
            <SlideDown className='my-dropdown-slidedown'>
                <span className='search-icon'>
                    <TextInput
                        name='searchZip'
                        className='car-filter-input'
                        placeholder='Enter zip code'
                        value={displayZipCode}
                        mask={'99999-9999'}
                        beforeMasked={true}
                        onChange={onZipCodeChange}
                    />
                </span>
            </SlideDown>
        </div>}
    </Row>
}