import { actionTypes } from '@Contexts/Filter'
import { handleUserDeauth } from '@Services'
import { httpGet } from '@Services/API'
import { logError } from '@Services'
import { updateTimestamp } from '@Services/Credentials'

/**Hydrates the FilterContext state, or deauthorizes the user and moves them to the login page if unsuccessful.
 * @param {*} auth The context yielded by AuthContext
 * @param {*} filter The context yielded by FilterContext
 * @param {*} setLoading The state callback for indicating the page is loading content
 */
export const hydrateFilters = async (auth, filter, setLoading) => {
    try {
        setLoading(prevLoadingCount => prevLoadingCount + 1)

        const { data } = await httpGet('/filters', auth.state.token)
        updateTimestamp()
        filter.dispatch({ type: actionTypes.HYDRATE_FILTER_STATE, payload: data })

        setLoading(prevLoadingCount => prevLoadingCount - 1)
    } catch (error) {
        if (handleUserDeauth(error, auth.dispatch)) return

        logError(error)
        setLoading(false)
    }
}
