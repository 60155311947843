import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { CollapsibleContainer } from '../CollapsibleContainer'
import { RadioFilter } from '@Components/shared/RadioComponents/RadioFilter'

/**Collapsible filter for the desired make of displayed cars.
 * @param {Function} [setPageNumber] callback to set the current page number on filter change.
 */
export const MakeFilter = ({ setPageNumber }) => {
  const { state, dispatch } = useContext(FilterContext)

  return <CollapsibleContainer title='MAKE'>
    <RadioFilter
      options={Object.entries(state.make).map(([key, value]) => ({ text: key, active: value }))}
      onClick={clicked => {
        dispatch({ type: actionTypes.MAKE_OPTIONS, payload: { [clicked.text]: !clicked.active } })
        setPageNumber && setPageNumber(1)
      }}
    />
  </CollapsibleContainer>
}
