import './styles.scss'

import { Container, Row } from 'react-bootstrap'

import AdContainer from '@Components/shared/Ads/AdContainer'
import { CarCard } from './CarCard/CarCard'
import { MobileAd } from '@Components/shared/Ads/MobileAd'
import React from 'react'

const ListResults = ({ carList }) => {
  if (!carList?.length || carList.every(car => !car)) return <div className='no-results'>No Results at this time</div>

  const carComponents = carList.map((car, index) => <CarCard key={index} {...car} />)

  return <Container>
    <Row className='justify-content-around'>
      {carComponents.slice(0, 5)}
      <MobileAd type='Warranty' />
      {carComponents.slice(5, 10)}
      <MobileAd type='Detailing' />
      <AdContainer />
      {carComponents.slice(10, 15)}
      <MobileAd type='Inspection' />
      {carComponents.slice(15, 20)}
    </Row>
  </Container>
}

export default ListResults
