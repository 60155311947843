import 'react-slidedown/lib/slidedown.css'

import React from 'react'
import { Row } from 'react-bootstrap'
import { SearchInput } from './SearchInput'
import { Sort } from './Sort'

const ListSearch = ({ totalVehicles }) =>
  <div className='search-container'>
    <Row className='justify-content-around'>
      <div className='search-container-vehicles'>{`${totalVehicles} Vehicle${totalVehicles === 1 ? '' : 's'}`}</div>
      <SearchInput />
      <Sort />
    </Row>
  </div>

export default ListSearch

