import './styles.scss'

import { Card, Col, Row } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import React from 'react'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

const Ad = ({ to, text, title }) => <Link to={to}>
  <Card className='ad'>
    <Row className='no-gutters'>
      <Col md='11' className='p-3'>
        <Card.Title className='ad-header'>{title}</Card.Title>
        <Card.Text className='ad-text'>{text}</Card.Text>
      </Col>
      <Col md='1' className='ad-chevron'>
        <FontAwesomeIcon size='3x' icon={faCaretRight} />
      </Col>
    </Row>
  </Card>
</Link>

export default Ad