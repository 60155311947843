import React from 'react'

/** Handles display of the photo for Search page cards.
 * 
 * @param {*} [props.glamourphotos] The array response from the Search page's API query.
 * @param {*} [props.stockPhoto] A stock fallback image to use in place of a server provided photo.
 */
export const Photo = ({ glamourphotos, manufacturerphotos, stockPhoto }) => {
    if (glamourphotos?.[0]?.image_url)
        return <div className='photo-container background-spinner'>
            <img className='photo' src={glamourphotos[0].image_url} alt='' />
        </div>

    if (manufacturerphotos?.[0]?.image_url)
        return <div className='photo-container background-spinner'>
            <img className='photo' src={manufacturerphotos[0].image_url} alt='' />
            <div className='stock-photo-banner'>Manufacturer Photo</div>
        </div>

    if (stockPhoto)
        return <div className='photo-container background-spinner'>
            <img className='photo' src={stockPhoto} alt='' />
            <div className='stock-photo-banner'>Manufacturer Photo</div>
        </div>

    return <div className='photo-container'>
        <img
            className='no-photo'
            src='data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
            alt=''
        />
    </div>
}