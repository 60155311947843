import './styles.scss'

import { AvailabilityFilter, LocationFilter, MakeFilter, MileageFilter, PriceFilter, SiriusXM, YearFilter } from './Filters'

import { ClearFilters } from './ClearFilters'
import React from 'react'

/** The container component for all filters on the left sidebar in the search page.
 * @param {Function} setPageNumber a callback for resetting page count when filters refresh 
 */
const FilterContainer = ({ setPageNumber }) =>
  <div className='filter-container'>
    <div className='filter-container-clear-filter'>
      <div>Filters</div>
      <ClearFilters />
    </div>
    <AvailabilityFilter setPageNumber={setPageNumber} />
    <LocationFilter setPageNumber={setPageNumber} />
    <PriceFilter setPageNumber={setPageNumber} />
    <MakeFilter setPageNumber={setPageNumber} />
    <MileageFilter setPageNumber={setPageNumber} />
    <YearFilter setPageNumber={setPageNumber} />
    <SiriusXM defaultActive={true} disabled={true} />
  </div>

export default FilterContainer