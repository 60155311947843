import React, { useState } from 'react'

import FilterHeader from './FilterHeader'

export const CollapsibleContainer = ({ title, children }) => {
    const [collapsed, setCollapsed] = useState(true)

    return <div className='filter'>
        <div className='car-filter'>
            <FilterHeader title={title} toggleState={collapsed} setToggleState={setCollapsed} />
            {collapsed && children}
        </div>
    </div>
}
