import { Card, Col, Row } from 'react-bootstrap'

import { AdContent } from './AdContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import React from 'react'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

export const MobileAd = ({ type }) => {
    if (!AdContent[type]) return null

    const { to, text } = AdContent[type]

    return <Link to={to} className='mobile-ad'>
        <Card className='ad mb-4'>
            <Row className='no-gutters'>
                <Col xs='11' sm='11' md='11' className='p-3'>
                    <Card.Title className='ad-header'>{type}</Card.Title>
                    <Card.Text className='ad-text'>{text}</Card.Text>
                </Col>
                <Col xs='1' sm='1' md='1' className='ad-chevron'>
                    <FontAwesomeIcon size='4x' icon={faCaretRight} />
                </Col>
            </Row>
        </Card>
    </Link>
}