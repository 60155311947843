import ChevronDown from '@Assets/icon-chevron-down.png'
import ChevronUp from '@Assets/icon-chevron-up.png'
import React from 'react'
import { Row } from 'react-bootstrap'

const FilterHeader = ({ title, toggleState, setToggleState }) =>
  <Row
    data-testid='filter-header-row'
    className='justify-content-between filter-header'
    onClick={() => setToggleState(!toggleState)}
  >
    <div className='filter-header-title'>{title}</div>
    <div>
      {toggleState
        ? <img src={ChevronDown} alt='chevron-down' />
        : <img src={ChevronUp} alt='chevron-up' />}
    </div>
  </Row>

export default FilterHeader
