import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext } from 'react'

import { DebounceInput } from 'react-debounce-input'

export const SearchInput = () => {
    const { state, dispatch } = useContext(FilterContext)

    return <div className='search-input'>
        <span className='search-icon'>
            <DebounceInput
                name='searchQuery'
                type='text'
                placeholder='Enter Year, Make, or Model'
                value={state.searchString}
                onChange={e => dispatch({ type: actionTypes.SEARCH_QUERY, payload: e.target.value })}
                minLength={0}
                debounceTimeout={1000}
            />
        </span>
    </div>
}