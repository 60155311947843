import '@Components/Pages/Search/mobile-styles.scss'
import '@Components/Pages/Search/styles.scss'

import { Col, Row } from 'react-bootstrap'
import { FilterContext, actionTypes } from '@Contexts/Filter'
import React, { useContext, useEffect, useState } from 'react'

import { AuthContext } from '@Contexts/Auth'
import FilterContainer from '@Components/Pages/Search/Filter/FilterContainer'
import ListContainer from '@Components/Pages/Search/List/ListContainer'
import ReactPaginate from 'react-paginate'
import { hydrateFilters } from '@Components/Pages/Search/hydrateFilters'
import { updateCarListings } from '@Components/Pages/Search/updateCarListings'

//Intentional no-op
const setPageNumber = () => { }

const Search = () => {
  const filter = useContext(FilterContext)
  const auth = useContext(AuthContext)
  const [carList, setCarList] = useState()
  const [loading, setLoading] = useState(0)
  const [pageInitialized, setPageInitialized] = useState(false)
  const [currentCancelToken, setCancelToken] = useState()
  const [resetFilter, setResetFilters] = useState(false)

  useEffect(() => {
    if (!auth.state.authorized || !filter.state.needFiltersState) return
    
    (async () => await hydrateFilters(auth, filter, setLoading))()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.state.authorized, filter.state.needFiltersState])

  useEffect(() => {
    if (filter.state.needFiltersState) return
    (async () => await updateCarListings(auth, filter, setLoading, currentCancelToken, setCancelToken, setCarList, setPageInitialized))()
    setPageInitialized(true)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.state])

  //This is probably where the double tap is coming from. This will call again (and render again) right after the initial call.
  //The only reason this isn't an infinite loop is because the value is set to false and then checked before being called again
  //Its like a latch.
  //This might even be redundant now that the filter context state lives entirely in one place
  useEffect(() => {
    resetFilter && setResetFilters(false)
  }, [resetFilter])

  const pageChange = (values) => {
    filter.dispatch({ type: actionTypes.SET_PAGE, payload: values.selected })
    window.scrollTo(0, 0)
  }

  return <Row>
    <Col lg='3' md='4' sm='0' className='d-sm-none d-none d-md-block pl-0 pr-0'>
      <FilterContainer setPageNumber={setPageNumber} />
    </Col>
    <Col lg='9' md='8' sm='12' className='pr-0 pl-0 list-flex-bottom'>
      <ListContainer
        carList={carList}
        setCarList={setCarList}
        setResetFilters={setResetFilters}
        loading={loading || !pageInitialized}
        setPageNumber={setPageNumber}
      />
      <ReactPaginate
        pageCount={carList?.numOfPages || 1}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        initialPage={filter.state.pageNumber || 0}
        forcePage={filter.state.pageNumber}
        onPageChange={pageChange}
        containerClassName='list-pagination'
        disableInitialCallback={true}
        activeClassName='list-pagination-active'
      />
    </Col>
  </Row>
}

export default Search
