import React, { useState } from 'react'

import InputMask from 'react-input-mask'

const TextInput = ({ onChange, className, name, value, onBlur, disabled, placeholder, mask, beforeMasked, id }) => {

  const [maskedValue, setMaskedValue] = useState(value)

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    //parses masked value if not needed
    var { value } = newState
    var selection = newState.selection
    var cursorPosition = selection ? selection.start : null

    // keep minus if entered by user

    if (userInput !== ',' &&
      ((value.endsWith(',') && !maskedValue.toString().endsWith(',')) ||
        (value.endsWith('-') && !maskedValue.toString().endsWith('-')))) {
      if (cursorPosition === value.length) {
        cursorPosition--
        selection = { start: cursorPosition, end: cursorPosition }
      }
      value = value.slice(0, -1)
    }

    return {
      value,
      selection
    }
  }

  const maskedChanges = (e) => {
    //change mask value and call props onChange
    onChange(e)
    setMaskedValue(e.target.value)
  }

  //Common properties for resulting TextInput
  const inputMaskProperties = {
    className,
    name,
    type: 'text',
    disabled,
    placeholder,
    id
  }

  if (beforeMasked) {
    //this is for masks that can be removed if not needed
    //ex) dollar amount 444 will not show a comma until next value is added
    return <InputMask
      data-testid='input-mask'
      {...inputMaskProperties}
      beforeMaskedValueChange={beforeMaskedValueChange}
      value={value}
      onChange={(e) => maskedChanges(e)}
      mask={mask || null}
      maskChar={null}
    />
  }
  else {
    return id === 'availabilityFilterLow' && value === 0 //for the availability filter show 'Today' if 0
      ? <input
        {...inputMaskProperties}
        value='Today'
        onChange={onChange}
        onBlur={onBlur}
        mask={mask || null}
      />
      : <InputMask
        {...inputMaskProperties}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        mask={mask || null}
        maskChar={null}
      />
  }
}

export default TextInput