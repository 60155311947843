import './styles.scss'

import ListResults from './ListResults'
import ListSearch from './ListSearch'
import React from 'react'

const ListContainer = ({ carList, setCarList, setResetFilters, loading }) =>
  <div className='list-container'>
    <ListSearch totalVehicles={carList.total} setCarList={setCarList} setResetFilters={setResetFilters} />
    {loading
      ? <div className='loading-ring-container'><div className='loading-ring'></div></div>
      : <ListResults carList={carList.vehicle} />}
  </div>

export default ListContainer

ListContainer.defaultProps = {
  carList: {
    vehicles: [],
    total: '',
  },
}
